import { storyStep, StepMap2Enum, MemberStepInStory } from '../../shared/constant'
import {
  SET_CURRENT_STEP,
  SET_STORIES,
  SET_STORY,
  SET_LAST_STEP,
  SET_PREVENT_REDIRECT,
  SET_SHOW_RECORDING_SUCCESS,
  SET_STORY_LOADING,
  SET_STORIES_LIST,
  SET_STORIES_LIST_LOADER,
  SET_IS_SUBMIT,
  SET_PINYIN_SETTINGS,
  SET_WORD_DETAIL,
  SET_WORD_TYPES,
  CHECK_WORD_IS_CLICKED,
} from '../types'

interface IStoryState {
  stories: any
  storyLoading: boolean
  currentStory: any
  currentStep: any
  lastStep: any
  lastStepEnum: any
  preventRedirect: boolean
  showRecordingSuccess: boolean
  storiesList: any
  storiesListLoader: boolean
  user_exist: boolean
  storyLevel: string
  welcomeMessage: string
  story_submitted: any
  CTAMessage: any
  pinyin_settings: any
  wordDetailLoader: boolean
  wordDetails: any
  isWordClick: boolean
}

const initialState: IStoryState = {
  stories: [],
  storyLoading: true,
  currentStory: {},
  currentStep: storyStep.Intro,
  lastStep: null,
  lastStepEnum: null,
  preventRedirect: false,
  showRecordingSuccess: false,
  storiesList: [],
  storiesListLoader: false,
  user_exist: false,
  storyLevel: '',
  welcomeMessage: '',
  story_submitted: null,
  CTAMessage: null,
  pinyin_settings: { new_word: 'always', recently_learned_word: 'hover', known_word: 'never' },
  wordDetailLoader: false,
  wordDetails: null,
  isWordClick: false,
}

export default function storyReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_STORY_LOADING:
      const tempPayload = action?.payload || {}
      return {
        ...state,
        ...tempPayload,
      }
    case SET_STORIES:
      return {
        ...state,
        stories: [...action.payload],
        currentStory: {},
      }
    case SET_STORY:
      return {
        ...state,
        currentStory: {
          ...action.payload,
        },
        storyLevel: action.payload.level,
      }
    case SET_CURRENT_STEP:
      return {
        ...state,
        currentStep: action.payload,
      }
    case SET_LAST_STEP:
      const lastStep = action.payload || MemberStepInStory.INTRODUCTION
      return {
        ...state,
        lastStep: lastStep,
        lastStepEnum: StepMap2Enum[lastStep],
      }
    case SET_PREVENT_REDIRECT:
      return {
        ...state,
        preventRedirect: action.payload,
      }
    case SET_SHOW_RECORDING_SUCCESS:
      return {
        ...state,
        showRecordingSuccess: action.payload,
      }
    case SET_STORIES_LIST:
      return {
        ...state,
        storiesList: action.payload?.data,
        user_exist: action.payload?.user_exist,
        welcomeMessage: action.payload?.welcomeMessage,
        CTAMessage: action.payload?.CTAMessage,
      }
    case SET_STORIES_LIST_LOADER:
      return {
        ...state,
        storiesListLoader: action.payload,
      }
    case SET_IS_SUBMIT:
      return {
        ...state,
        story_submitted: action.payload,
      }
    case SET_PINYIN_SETTINGS:
      return {
        ...state,
        pinyin_settings: action.payload,
      }
    case SET_WORD_DETAIL:
      return {
        ...state,
        wordDetailLoader: action.payload.loader,
        wordDetails: action.payload.detail,
      }
    case SET_WORD_TYPES:
      return {
        ...state,
        currentStory: {
          ...state?.currentStory,
          ...action.payload,
        },
      }
    case CHECK_WORD_IS_CLICKED:
      return {
        ...state,
        isWordClick: action.payload,
      }
    default:
      return state
  }
}
