import React, { useEffect, useState } from 'react'
import Parser from 'html-react-parser'
import { extractDefinition } from 'utils/utils'
import { converterWord, getWordTypeClass, removeSpaceBetweenWords } from 'utils/helperFunction'
import { wordClick, wordContext } from 'redux/actions/storyAction'
import { useParams } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { Carousel } from 'react-bootstrap'

interface IWordDetailProps {
  visible?: boolean
  memberId?: string
}
const downArrow = <i className='fa fa-angle-down' />
const upArrow = <i className='fa fa-angle-up' />

const WordDetail = React.forwardRef<HTMLInputElement, IWordDetailProps>(
  (
    { visible, setOpenExampleModal, memberId, isMasteryOpen, setMasteryOpen, isImportanceOpen, setImportanceOpen }: any,
    ref: any,
  ) => {
    const { id }: any = useParams()
    const { wordDetailLoader, wordDetails, currentStory }: any = useSelector<any>(({ story }) => story)
    const [expandContext, setExpandContext] = useState(false)
    const [contextState, setContextState] = useState({ loader: false, val: '', error: false })
    const [wordChangeLoader, setWordChangeLoader] = useState({ loader: false, val: '' })
    const [wordType, setWordType] = useState(wordDetails?.mastery)
    const [wordTypeList, setWordList] = useState(
      getWordTypeClass(Number.isInteger(wordDetails?.mastery) ? 'word' : wordDetails?.mastery),
    )

    useEffect(() => {
      setWordType(wordDetails?.mastery)
      setWordList(getWordTypeClass(Number.isInteger(wordDetails?.mastery) ? 'word' : wordDetails?.mastery))
      if (wordDetailLoader) {
        setExpandContext(false)
        setContextState({ loader: false, val: '', error: false })
      }
    }, [wordDetailLoader, wordDetails?.mastery])

    useEffect(() => {
      const handleClickOutside = (event) => {
        const target = event.target as HTMLElement
        if (!target.closest('.modal_tooltip_div')) {
          setImportanceOpen(false)
          setMasteryOpen(false)
        }
      }

      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [])

    const handle = {
      wordClick: (word, word_status, wordName) => {
        setWordChangeLoader({ loader: true, val: word_status })
        wordClick({ word, word_status, storyId: id, memberId, mastery: wordDetails?.mastery }).then((res) => {
          if (res) {
            setWordChangeLoader({ loader: false, val: '' })
            setWordType(wordName)
            setWordList(getWordTypeClass(wordName))
            setMasteryOpen(false)
          }
        })
      },
      getWordContext: async () => {
        setExpandContext(!expandContext)
        if (!contextState?.val) {
          setContextState({
            ...contextState,
            loader: true,
          })
          await wordContext({ sentence: wordDetails?.sentenceString, word: wordDetails?.word })
            .then((res) => {
              setContextState({
                val: res || '',
                loader: false,
                error: true,
              })
            })
            .catch((err) => {
              setContextState({
                val: '',
                loader: false,
                error: true,
              })
            })
        }
      },
    }
    return (
      <>
        <div ref={ref} className={`word_detail_modal_main_div ${visible ? 'bounce-in-right' : ' '}`}>
          {wordDetailLoader ? (
            <div className='word_detail_card_skeleton'>
              <div className='word_details_head_div'>
                <h5 className='big_word'>{wordDetails?.word}</h5>
                <p className='small_word'>
                  {wordDetails?.test_level
                    ?.map((levelDetail) => `${levelDetail?.test} ${levelDetail?.level}`)
                    .join(' / ')}
                </p>
              </div>
              <div style={{ marginBottom: '24px' }}>
                {wordDetails?.pinyin && <p className='speck_text'>{removeSpaceBetweenWords(wordDetails?.pinyin)}</p>}
                {wordDetails?.english && (
                  <p className='speck_text'>{Parser(extractDefinition(wordDetails?.english))}</p>
                )}
              </div>
              <div style={{ marginBottom: '24px' }} className='sk_mob_hide'>
                <div className='para_skeleton skeleton_animation mb-3'></div>
                <div className='para_skeleton skeleton_animation'></div>
              </div>

              {currentStory?.is_recommended && (
                <>
                  <div className='divider_line sk_mob_hide'></div>
                  <div className='skeleton_mob_space'>
                    <div
                      className='para_skeleton skeleton_animation'
                      style={{ marginBottom: '16px', height: '20px' }}
                    ></div>
                    <div
                      className='para_skeleton skeleton_animation sk_mob_hide'
                      style={{ marginBottom: '17px', height: '45px' }}
                    ></div>
                    <div className='para_skeleton skeleton_animation' style={{ height: '19px' }}></div>
                  </div>
                </>
              )}
            </div>
          ) : (
            wordDetails && (
              <>
                <div className='word_details_head_div'>
                  <h5 className='big_word'>{converterWord(wordDetails?.word)}</h5>
                  <p className='small_word'>
                    {wordDetails?.test_level
                      ?.map(
                        (levelDetail: { test: string; level: string }): string =>
                          `${levelDetail.test} ${levelDetail.level}`,
                      )
                      .join(' / ')}
                  </p>
                </div>
                <div className='word_detail_slider_desktop_div'>
                  <FirstSlide
                    wordDetails={wordDetails}
                    handle={handle}
                    expandContext={expandContext}
                    contextState={contextState}
                    setOpenExampleModal={setOpenExampleModal}
                  />
                  {currentStory?.is_recommended && (
                    <SecondSlide
                      wordDetails={wordDetails}
                      setImportanceOpen={setImportanceOpen}
                      isImportanceOpen={isImportanceOpen}
                      wordType={wordType}
                      setMasteryOpen={setMasteryOpen}
                      isMasteryOpen={isMasteryOpen}
                      wordChangeLoader={wordChangeLoader}
                      handle={handle}
                      visible={visible}
                      wordTypeList={wordTypeList}
                    />
                  )}
                </div>
                <div className='word_detail_slider_div'>
                  <Carousel interval={null} indicators={currentStory?.is_recommended && true}>
                    <Carousel.Item>
                      <FirstSlide
                        wordDetails={wordDetails}
                        handle={handle}
                        expandContext={expandContext}
                        contextState={contextState}
                        setOpenExampleModal={setOpenExampleModal}
                      />
                    </Carousel.Item>
                    {currentStory?.is_recommended && (
                      <Carousel.Item>
                        <SecondSlide
                          wordDetails={wordDetails}
                          setImportanceOpen={setImportanceOpen}
                          isImportanceOpen={isImportanceOpen}
                          wordType={wordType}
                          setMasteryOpen={setMasteryOpen}
                          isMasteryOpen={isMasteryOpen}
                          wordChangeLoader={wordChangeLoader}
                          handle={handle}
                          visible={visible}
                          wordTypeList={wordTypeList}
                        />
                      </Carousel.Item>
                    )}
                  </Carousel>
                </div>
              </>
            )
          )}
        </div>
      </>
    )
  },
)

const FirstSlide = ({ wordDetails, handle, expandContext, contextState, setOpenExampleModal }) => {
  return (
    <>
      <div className='first_slide_main_div'>
        <div style={{ marginBottom: '24px' }}>
          {wordDetails?.pinyin && <p className='speck_text'>{removeSpaceBetweenWords(wordDetails?.pinyin)}</p>}
          {wordDetails?.english && <p className='speck_text'>{Parser(extractDefinition(wordDetails?.english))}</p>}
        </div>
        <div className='tooltip_text_main_div'>
          <div>
            <p className='modal_tooltip_text' onClick={() => handle.getWordContext()}>
              Explain in context {expandContext ? upArrow : downArrow}
            </p>
            <>
              {expandContext &&
                (contextState?.loader ? (
                  <div className='para_skeleton skeleton_animation mt-2'></div>
                ) : (
                  <p className={`para_show_text ${expandContext ? 'fadeincls' : ''}`}>
                    {contextState.val || 'Currently data is not available'}
                  </p>
                ))}
            </>
          </div>

          {wordDetails?.sentence && (
            <p className='modal_tooltip_text' onClick={() => setOpenExampleModal(true)}>
              View examples
            </p>
          )}
        </div>
      </div>
    </>
  )
}

const SecondSlide = ({
  wordDetails,
  setImportanceOpen,
  isImportanceOpen,
  wordType,
  setMasteryOpen,
  isMasteryOpen,
  wordChangeLoader,
  handle,
  visible,
  wordTypeList,
}) => {
  return (
    <>
      <div className='divider_line'></div>
      <div className='sec_slide_main_div'>
        {/* slider code end */}
        {wordDetails?.importance && (
          <p className='word_detail_small_text position-relative'>
            Importance: {''}
            <span className='bold_text'>
              {wordDetails?.importance}
              <img
                className='ms-2 curser_pointer'
                style={{ position: 'relative', top: '-1px', cursor: 'pointer' }}
                alt={'icon'}
                src={'/images/help-circle.svg'}
                onClick={() => setImportanceOpen(!isImportanceOpen)}
              />
            </span>
            {isImportanceOpen ? (
              <div className='modal_tooltip_div'>
                <p className='tooltip_para_text'>
                  This recommendation is based on words you already know and how frequently you'll see this word in
                  future stories. There are three levels of importance: must learn, should learn and nice to know.
                </p>
              </div>
            ) : null}
          </p>
        )}
        <div>
          {!Number.isInteger(wordType) && (
            <p className='word_detail_small_text position-relative' style={{ marginBottom: '10px' }}>
              Mastery: {''}
              <span className='bold_text'>
                {wordType}
                <img
                  className='ms-2'
                  style={{ position: 'relative', top: '-1px', cursor: 'pointer' }}
                  alt={'icon'}
                  src={'/images/help-circle.svg'}
                  onClick={() => setMasteryOpen(!isMasteryOpen)}
                />
              </span>
              {isMasteryOpen ? (
                <div className={`modal_tooltip_div ${visible ? 'actions' : ' '}`}>
                  <p className='tooltip_para_text'>
                    We determined your mastery by how often you looked up a word in the dictionary. If this is
                    inaccurate, you can correct it manually.
                  </p>
                  <div className='tooltip_btn_div'>
                    <button
                      className='btn tooltip_btn'
                      disabled={wordChangeLoader?.loader || wordType === 'Mastered'}
                      onClick={() => handle.wordClick(wordDetails?.word, 'known', 'Mastered')}
                    >
                      {wordChangeLoader?.loader && wordChangeLoader?.val === 'known'
                        ? 'Loading...'
                        : 'Mark as mastered'}
                    </button>
                    <button
                      className='btn tooltip_btn'
                      disabled={wordChangeLoader?.loader || wordType === 'New'}
                      onClick={() => handle.wordClick(wordDetails?.word, 'new', 'New')}
                    >
                      {wordChangeLoader?.loader && wordChangeLoader?.val === 'new' ? 'Loading...' : 'Mark as new'}
                    </button>
                  </div>
                </div>
              ) : null}
            </p>
          )}
          {wordTypeList && (
            <div className='process_box_main_div'>
              {wordTypeList?.map((className, i) => (
                <div className={`process_box ${className}`} key={i}></div>
              ))}
            </div>
          )}
        </div>
        {wordDetails?.last_seen && wordType !== 'New' && (
          <p className='word_detail_small_text mb-0'>Last seen {wordDetails?.last_seen}</p>
        )}
      </div>
    </>
  )
}

export default WordDetail
