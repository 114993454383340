import store from '../stores'
import axios from 'axios'
import MAAYOT_API, { JSON_HEADER } from './config'
import {
  MANAGE_WORDLIST_LOADER,
  SET_ALL_LANGUAGE,
  SET_LEARN_ABOUT,
  SET_LEARN_ABOUT_LOADING,
  SET_LEARN_ABOUT_TITLE,
  SET_LOADER_RECOMMENDED,
  SET_STEPPER_STEP,
  SET_WORD_RESULT,
} from 'redux/types'
import axiosInstance from 'axios/axiosInstance'

declare const MemberStack: any

export const getWordsList = async (data: any) => {
  try {
    store.dispatch({ type: MANAGE_WORDLIST_LOADER, payload: true })
    const res = await axios.post(`${MAAYOT_API}language-dictionary/getRandomWords`, data, {
      headers: {
        ...JSON_HEADER,
        authorization: `Bearer ${MemberStack.getToken()}`,
      },
    })
    store.dispatch({ type: MANAGE_WORDLIST_LOADER, payload: false })
    return res?.data
  } catch (error: any) {
    if (error?.response?.data.statusCode === 400) {
      store.dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      window.location.href = '/'
      return
    }
    store.dispatch({ type: MANAGE_WORDLIST_LOADER, payload: false })
    return { status: 404, message: 'Something went wrong' }
  }
}

export const getLearnAboutMaayot = async () => {
  try {
    store.dispatch({ type: SET_LEARN_ABOUT_LOADING, payload: true })
    const res = await axios.get(`${MAAYOT_API}user-language/how-learn-about-maayot`, {
      headers: {
        ...JSON_HEADER,
        authorization: `Bearer ${MemberStack.getToken()}`,
      },
    })
    store.dispatch({ type: SET_LEARN_ABOUT_LOADING, payload: false })
    store.dispatch({ type: SET_LEARN_ABOUT, payload: res?.data?.data || [] })
    return res?.data
  } catch (error: any) {
    if (error?.response?.data.statusCode === 400) {
      store.dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      window.location.href = '/'
      return
    }
    store.dispatch({ type: SET_LEARN_ABOUT_LOADING, payload: false })
    return { status: 404, message: 'Something went wrong' }
  }
}

export const getLearnAboutMaayotTitle = async (data: any) => {
  try {
    const res = await axios.post(`${MAAYOT_API}user-language/learn-about-maayot-question`, data, {
      headers: {
        ...JSON_HEADER,
        authorization: `Bearer ${MemberStack.getToken()}`,
      },
    })
    store.dispatch({ type: SET_LEARN_ABOUT_TITLE, payload: res.data.data })
    return res?.data
  } catch (error: any) {
    if (error?.response?.data.statusCode === 400) {
      store.dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      window.location.href = '/'
      return
    }
    store.dispatch({ type: SET_LEARN_ABOUT_TITLE, payload: '' })
    return { status: 404, message: 'Something went wrong' }
  }
}

export const noKnownWord = async (data: any) => {
  try {
    const res = await axios.post(`${MAAYOT_API}language-dictionary/notKnownWord`, data, {
      headers: JSON_HEADER,
    })

    return res?.data
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const notLearnedLanguage = async (data: any) => {
  try {
    const res = await axios.post(`${MAAYOT_API}language-dictionary/notLearnedLanguage`, data, {
      headers: {
        ...JSON_HEADER,
        authorization: `Bearer ${MemberStack.getToken()}`,
      },
    })
    return res?.data
  } catch (error: any) {
    if (error?.response?.data.statusCode === 400) {
      store.dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      window.location.href = '/'
      return
    }
    return { status: 404, message: 'Something went wrong' }
  }
}

export const getAllLanguage = async () => {
  try {
    const response = await axios.get(`${MAAYOT_API}language/get/all`, {
      headers: JSON_HEADER,
    })
    store.dispatch({ type: SET_ALL_LANGUAGE, payload: response?.data?.data })
    return response?.data?.data
  } catch (error) {
    return { status: 404, message: 'Something went wrong' }
  }
}

export const getScore = async (data: any) => {
  try {
    const response = await axios.post(`${MAAYOT_API}language-dictionary/getScore`, data, {
      headers: {
        ...JSON_HEADER,
        authorization: `Bearer ${MemberStack.getToken()}`,
      },
    })
    store.dispatch({ type: SET_WORD_RESULT, payload: response?.data?.data?.finalScore })
    return response?.data?.data
  } catch (error: any) {
    if (error?.response?.data.statusCode === 400) {
      store.dispatch({ type: SET_STEPPER_STEP, payload: 1 })
      window.location.href = '/'
      return
    }
    return { status: 404, message: 'Something went wrong' }
  }
}

export const getRecommended = async (data: any) => {
  try {
    store.dispatch({ type: SET_LOADER_RECOMMENDED, payload: true })
    const response = await axiosInstance.post(`words/text-recomendation`, data)
    store.dispatch({ type: SET_LOADER_RECOMMENDED, payload: false })
    return response?.data?.data
  } catch (err: any) {
    store.dispatch({ type: SET_LOADER_RECOMMENDED, payload: false })
    return { status: 404, message: 'Something went wrong' }
  }
}
