import React from 'react'

interface ILoaderProps {
  showText?: boolean
  color?: string
}
const Loader: React.FC<ILoaderProps> = (props: any) => {
  const { showText = true, color } = props
  return (
    <div className={`m-center m-loading ${!showText ? 'no-text' : ''}`} style={{ color: color || '' }}>
      <div className='m-loading-ellipsis'>
        <div style={{ backgroundColor: color || '' }} />
        <div style={{ backgroundColor: color || '' }} />
        <div style={{ backgroundColor: color || '' }} />
        <div style={{ backgroundColor: color || '' }} />
      </div>
      {showText && <span className={'m-loading-text'}>Loading</span>}
    </div>
  )
}

export default Loader
