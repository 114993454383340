import React, { useState, useMemo } from 'react'
import Word from './Word'
import { converterWord } from 'utils/helperFunction'
import { ChevronIcon, HighlightIcon } from 'components/icons/icons'

interface WordObject {
  word: string
  pinyin?: string
}

interface StoryWord {
  word: string
}

interface Story {
  storyContent: WordObject[]
  newWordsArray?: StoryWord[]
  learningWords?: StoryWord[]
  growingWords?: StoryWord[]
  developingWords?: StoryWord[]
  masteredWords?: StoryWord[]
  oldWords?: string[]
  newWord1?: string
  newWord2?: string
  englishContent?: string[]
}

interface ILeftStoryProps {
  story: Story
  handleClickWord?: (e: React.MouseEvent, wordObj: WordObject, sentence: WordObject[], index: string) => void
  activeIndex?: string
  showPinYin?: boolean
  chineseWordFontSize?: {
    storyContent?: string
    pinyinValue?: string
  }
}

const LeftStory: React.FC<ILeftStoryProps> = ({
  handleClickWord,
  story,
  activeIndex,
  showPinYin,
  chineseWordFontSize,
}) => {
  const [showMessage, setShowMessage] = useState(-1)
  const [showChinese, setShowChinese] = useState(-1)
  const [showTranslation, setShowTranslation] = useState(false)

  const paragraphs = useMemo(() => {
    const result: WordObject[][] = [[]]

    story.storyContent.forEach((wordObj, index) => {
      const isNewParagraph =
        wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')

      if (isNewParagraph && index) {
        result.push([])
      } else {
        result[result.length - 1].push(wordObj)
      }
    })

    return result
  }, [story.storyContent])

  const findWordInArray = (word: string, wordArray?: StoryWord[]) => {
    return wordArray?.find((wordDetail) => converterWord(word) === converterWord(wordDetail.word))
  }

  const renderWord = (wordObj: WordObject, index: number, sentence: WordObject[], sIndex: number) => {
    const stripedWord = wordObj.word.replace(/<[^>]+>/g, '')
    if (!stripedWord.length) return null

    const newWord = findWordInArray(stripedWord, story.newWordsArray)
    const learningWord = findWordInArray(stripedWord, story.learningWords)
    const growingWord = findWordInArray(stripedWord, story.growingWords)
    const developingWord = findWordInArray(stripedWord, story.developingWords)
    const masteredWord = findWordInArray(stripedWord, story.masteredWords)

    const isOldWord = story.learningWords?.length ? !!learningWord : story.oldWords?.includes(wordObj.word)

    const isNewWord = story.newWordsArray?.length
      ? !!newWord
      : story.newWord1 === wordObj.word || story.newWord2 === wordObj.word

    const isIncludePTag =
      wordObj.word.includes('<p>') || wordObj.word.includes('</p>') || wordObj.word.includes('</p><p>')

    return (
      <React.Fragment key={index}>
        {isIncludePTag && index ? <br /> : null}
        <Word
          key={index}
          index={`L${sIndex}-${index}`}
          isActive={`L${sIndex}-${index}` === activeIndex}
          text={stripedWord}
          isNewWord={isNewWord}
          isGrowingWord={!!growingWord}
          isDevelopingWord={!!developingWord}
          isMasteredWord={!!masteredWord}
          isOldWord={!isNewWord && isOldWord}
          textObj={wordObj}
          sentance={sentence}
          onClick={handleClickWord}
          pinYinText={wordObj.pinyin}
          chineseWordFontSize={chineseWordFontSize?.storyContent}
          pinyinFontSize={chineseWordFontSize?.pinyinValue}
        />
      </React.Fragment>
    )
  }

  return (
    <div className='w-col w-col-12 content-left padding_remove_div section_story_container'>
      <div
        style={{
          fontSize: chineseWordFontSize?.storyContent,
          lineHeight: 'initial',
          letterSpacing: '0px',
          marginBottom: '5px',
        }}
        className='story-content story-event-select story_content_full_div'
      >
        {paragraphs.map((sentence, sIndex) => (
          <div
            key={sIndex}
            className={sIndex === showChinese ? 'sentance-hover' : ''}
            onMouseEnter={() => setShowMessage(sIndex)}
            onMouseLeave={() => setShowMessage(-1)}
            style={{
              marginBottom: '7px',
              display: 'flex',
              flexWrap: 'wrap',
              rowGap: '1px',
            }}
          >
            {sentence.map((wordObj, index) => renderWord(wordObj, index, sentence, sIndex))}
          </div>
        ))}
      </div>

      <div className='translation-box'>
        <div className='translation-button' onClick={() => setShowTranslation(!showTranslation)}>
          {showTranslation ? 'Hide' : 'Show'} English Translation
          <ChevronIcon isUp={showTranslation} />
        </div>

        {showTranslation && story.englishContent?.length > 0 && (
          <div
            style={{
              fontSize: chineseWordFontSize?.storyContent,
              lineHeight: '1.5',
              letterSpacing: '1.5px',
            }}
            className='story-content story-event-select m-b-10'
          >
            {story.englishContent?.map((sentence, index) => (
              <div
                key={index}
                className={`translation-text ${index === showMessage ? 'sentance-hover' : ''}`}
                onMouseEnter={() => setShowChinese(index)}
                onMouseLeave={() => setShowChinese(-1)}
              >
                {sentence}
              </div>
            ))}

            <div className='hight_light_text_main_div'>
              <HighlightIcon />
              <p className='hight_light_text'>
                This translation has been automatically generated. It may contain errors or inaccuracies.
              </p>
            </div>
          </div>
        )}
      </div>
      {/* <div className='mob_scroll_bottom_space'></div> */}
    </div>
  )
}

export { Word }
export default LeftStory
