import React, { useEffect, useState, useMemo } from 'react'
import { useSelector } from 'react-redux'
import { converterWord, removeSpaceBetweenWords } from 'utils/helperFunction'

interface WordProps {
  index?: any
  text?: any
  onClick?: any
  isActive?: boolean
  isNewWord?: boolean
  isOldWord?: boolean
  isGrowingWord?: boolean
  isDevelopingWord?: boolean
  isMasteredWord?: boolean
  pinYinText?: any
  pinyinFontSize?: any
  chineseWordFontSize?: any
  textObj?: any
  sentance?: any
  isFontBold?: boolean
}

const Word: React.FC<WordProps> = (props: any) => {
  const {
    index,
    text,
    isNewWord,
    isOldWord,
    isGrowingWord,
    isDevelopingWord,
    isMasteredWord,
    isActive,
    onClick,
    pinYinText,
    pinyinFontSize,
    chineseWordFontSize,
    textObj,
    sentance,
  } = props
  const [showPinyin, setShowPinyin] = useState(false)
  const { pinyin_settings }: any = useSelector<any>(({ story }) => story)
  const wordWithOutType = !isNewWord || !isOldWord || !isGrowingWord || !isDevelopingWord || !isMasteredWord

  const currentSetting = useMemo(() => {
    if (isNewWord) return pinyin_settings?.new_word
    if (isOldWord) return pinyin_settings?.recently_learned_word
    if (isGrowingWord || isDevelopingWord || isMasteredWord || wordWithOutType) {
      return pinyin_settings?.known_word
    }
    return null
  }, [pinyin_settings, isNewWord, isOldWord, isGrowingWord, isDevelopingWord, isMasteredWord, wordWithOutType])

  const displayMode = useMemo(() => {
    if (currentSetting === 'never') return 'never'
    if (currentSetting === 'always') return 'always'
    if (currentSetting === 'on_hover') return 'hover'
    return null
  }, [currentSetting])

  useEffect(() => {
    switch (displayMode) {
      case 'never':
        setShowPinyin(false)
        break
      case 'always':
        setShowPinyin(true)
        break
      case 'hover':
        setShowPinyin(false)
        break
      default:
        setShowPinyin(false)
    }
  }, [displayMode])

  const handleMouseOver = () => {
    if (displayMode === 'hover') {
      setShowPinyin(true)
    }
  }

  const handleMouseOut = () => {
    if (displayMode === 'hover') {
      setShowPinyin(false)
    }
  }

  const onWordClick = (e) => {
    onClick && onClick(e, textObj, sentance, index)
  }
  const className =
    `${onClick ? `word-translate` : ''} ` +
    `${isActive ? 'word-active' : ''} ` +
    `${isNewWord ? 'newword-highlight' : ''}` +
    `${isOldWord ? 'oldword-highlight' : ''}` +
    `${isGrowingWord ? 'growingword-highlight' : ''}` +
    `${isDevelopingWord ? 'developingword-highlight' : ''}` +
    `${isMasteredWord ? 'masterword-highlight' : ''}`

  return (
    <span id={index} className={`d-inline-grid ${pinYinText ? 'ml-2 mr-2' : ''}`} onClick={onWordClick}>
      <span className={'word-container'}>
        <span
          style={{
            fontSize: pinyinFontSize || '12px',
            lineHeight: '1.5',
            // letterSpacing: '1.5px',
            visibility: showPinyin ? 'visible' : 'hidden',
          }}
          className={`pinyin-word m-al-c main_title_small_text`}
        >
          {removeSpaceBetweenWords(pinYinText)}
        </span>
        <span
          style={{
            fontSize: chineseWordFontSize || '20px',
            lineHeight: chineseWordFontSize && '1.2',
          }}
          data-specific-buttons
          className={`${className} m-al-c main_title_big_text`}
          onMouseOver={handleMouseOver}
          onMouseOut={handleMouseOut}
        >
          {converterWord(text)}
        </span>
      </span>
    </span>
  )
}
export default Word
