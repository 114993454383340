import React from 'react'
import { Modal } from 'react-bootstrap'
import { useSelector } from 'react-redux'
import { converterWord } from 'utils/helperFunction'

interface IWordExamplesProps {
  wordRef: any
  openExampleModal?: boolean
  setOpenExampleModal?: any
}

const WordExamplesModal = React.forwardRef<HTMLInputElement, IWordExamplesProps>(
  ({ openExampleModal, setOpenExampleModal }: any, ref: any) => {
    const { wordDetails }: any = useSelector<any>(({ story }) => story)
    const findColorClass = (key) => {
      switch (key) {
        case 'New':
          return 'newword-highlight'

        case 'Learning':
          return 'oldword-highlight'

        case 'Growing':
          return 'growing_developing_mastering'

        case 'Developing':
          return 'growing_developing_mastering'

        case 'Mastered':
          return 'growing_developing_mastering'
        default:
          return ''
      }
    }

    const wordColorClass = findColorClass(wordDetails?.type)
    return (
      <div className='custom_modal'>
        <Modal
          show={openExampleModal}
          onHide={() => {
            setOpenExampleModal(false)
          }}
          size='lg'
          aria-labelledby='contained-modal-title-vcenter'
          centered
          className='common_modal_main_div'
        >
          <Modal.Body className='mobile_align_div'>
            <div ref={ref}>
              <div className='modal_title_div'>
                <h4 className='modal_heading'>View sentence examples</h4>
                <div
                  className='modal_close_icon_div'
                  onClick={() => {
                    setOpenExampleModal(false)
                    // setWordDetailVisible(true)
                  }}
                >
                  <img className='' alt={'icon'} src={'/images/close-icon-black.svg'} />
                </div>
              </div>

              <div className='sentence_list_main_div'>
                {wordDetails?.sentence?.length > 0 ? (
                  wordDetails?.sentence?.map((sentenceInfo, i) => (
                    <div key={i} className='sentence_box_div'>
                      <p className='sentence_english'>{sentenceInfo?.english}</p>
                      <div className='view_sentence_word_div'>
                        {sentenceInfo?.segmentation?.map((wordInfo, i) => (
                          <div key={i}>
                            {wordInfo?.pinyin ? (
                              <p className='sentence_pinyin'>{wordInfo?.pinyin}</p>
                            ) : (
                              <p className='sentence_pinyin' style={{ visibility: 'hidden' }}>
                                1
                              </p>
                            )}
                            <p
                              className={`sentence_sentence_text ${
                                wordInfo?.word === wordDetails?.word && wordColorClass
                              }`}
                            >
                              {converterWord(wordInfo?.word)}
                            </p>
                          </div>
                        ))}
                      </div>
                    </div>
                  ))
                ) : (
                  <p className='server_error_msg'>Currently data is not available</p>
                )}
              </div>
            </div>
          </Modal.Body>
        </Modal>
      </div>
    )
  },
)

export default WordExamplesModal
