import history from '../../browserHistory'
import { membershipType } from '../../shared/constant'
import { ACCOUNT_SET_HACK_CHINESE, ACCOUNT_SET_SKRITTER, SET_AUTHENTICATED, SET_UNAUTHENTICATED } from '../types'
import { LOGIN } from 'paths'
import axios from 'axios'
import { getSkritterToken, getSkritterWordPayload, refreshSkritterAuthToken } from 'services/skritter'
import store from 'redux/stores'
import posthog from 'posthog-js'

declare let MemberStack: any
const { REACT_APP_HACK_CHINESE_APP_KEY } = process.env

const { REACT_APP_MAAYOT_API_DEV, REACT_APP_MAAYOT_API_PROD, NODE_ENV } = process.env

// check member login
export const checkAuthen = async () => {
  MemberStack.onReady
    .then((member: any) => {
      if (typeof member.email !== undefined && member.email !== 'undefined' && member.email) {
        //set state authenticated
        // console.log('member', member)
        // console.log({ customFields: member.customFields })
        const status =
          member.membership.amount && parseInt(member.membership.amount) > 0 ? membershipType.Paid : membershipType.Free
        const credentials = {
          memberId: member.id,
          email: member.email,
          metaData: {
            firstName: member?.['first-name'],
          },
          customFields: {
            'first-name': member.customFields?.['first-name'],
            level: member.customFields?.level?.toLowerCase() || member?.level || '',
          },
          //level: member.level.toLowerCase(),
        }
        const membership = {
          id: member.membership.id,
          name: member.membership.name,
          amount: member.membership.amount,
          oneTime: false,
          status,
          plan: member.membership.plan,
        }
        localStorage.setItem(
          'user',
          JSON.stringify({ memberId: credentials.memberId, status, membershipName: membership.name }),
        )
        getIntegrationsTokenForUser(member.id).then(async (data: any) => {
          store.dispatch({ type: ACCOUNT_SET_HACK_CHINESE, payload: { token: data.hack_chinese_token } })

          let skritter = data.skritter
          let skritter_token = data?.skritter_token

          if (data?.skritter_token) {
            // expires_in was in seconds
            // if current time less than or equal to Skritter access_token's expiration time (minus 1 Hours)
            // refetch new access_token
            const expires_in = skritter.expires_in ? parseInt(skritter.expires_in) : 0
            const cTimeSeconds = Math.floor(Date.now() / 1000)

            if (cTimeSeconds >= expires_in - 3600) {
              try {
                const res: any = await refreshSkritterAuthToken(skritter.refresh_token)
                if (res.access_token) {
                  skritter_token = res.access_token
                  skritter.refresh_token = res.refresh_token
                  skritter.expires_in = (parseInt(res.expires_in) + cTimeSeconds).toString()
                  skritter.skritter_token = skritter_token

                  const updatePayload = {
                    skritter_token: skritter_token,
                    refresh_token: skritter.refresh_token,
                    expires_in: skritter.expires_in.toString(),
                    user_id: skritter.user_id,
                    token_type: skritter.token_type,
                  }
                  const updateRes = await updateNewTokenToDB(member.id, updatePayload)

                  store.dispatch({
                    type: ACCOUNT_SET_SKRITTER,
                    payload: { token: skritter_token, skritter: skritter },
                  })
                  localStorage.setItem('skritterData', JSON.stringify({ ...skritter }))
                }
              } catch (error) {}
            }
          }
          if (!skritter) {
            skritter = {}
          }
          store.dispatch({
            type: ACCOUNT_SET_SKRITTER,
            payload: {
              token: skritter_token || '',
              skritter: skritter,
            },
          })
          localStorage.setItem('skritterData', JSON.stringify({ ...skritter }))
        })
        store.dispatch({ type: SET_AUTHENTICATED, payload: { credentials, membership } })
      } else {
        clearLocal()
        store.dispatch({ type: SET_UNAUTHENTICATED })
        history.push(LOGIN)
      }
    })
    .catch(() => {
      clearLocal()
      store.dispatch({ type: SET_UNAUTHENTICATED })
    })
}

// test get story from prismic throw be-maayot - structure later
export const getStory = async (memberId: string, level: string): Promise<any> => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
  }
  return fetch(`${maayotApiUrl}prismic?level=${level?.toLowerCase() || ''}`, {
    method: 'GET',
    headers,
  })
}

export const updateNewTokenToDB = async (memberId, skritter) => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    return axios
      .put(`${maayotApiUrl}skritter/${memberId}`, skritter, {
        headers: headers,
      })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const removeSkritterTokenForUser = (memberId: string) => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    return fetch(`${maayotApiUrl}skritter/${memberId}`, {
      method: 'DELETE',
      headers,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const registerWithSkritter = (payload) => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`${maayotApiUrl}skritter`, payload, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
      })
  })
}

export const AddWordToHackChinese = async (iword: any, user_auth_token: any) => {
  const data = {
    words: [
      {
        simplified: iword?.word,
        pinyin: iword?.pinyin,
      },
    ],
    user_auth_token: user_auth_token,
  }
  try {
    const headers = {
      Authorization: `${REACT_APP_HACK_CHINESE_APP_KEY}`,
      'Content-Type': 'application/json',
    }
    const response = await axios.post('https://www.hackchinese.com/add_words_to_user_list', data, {
      headers: headers,
    })
  } catch (error) {
    console.log('error', error)
  }
}

export const removeHackChineseTokenForUser = async (memberId: string): Promise<any> => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    return fetch(`${maayotApiUrl}hack-chinese/${memberId}`, {
      method: 'PUT',
      headers,
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const postHackChineseTokenForUser = async (memberId: string, user_auth_token: string): Promise<any> => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    const content = {
      memberId: memberId,
      hack_chinese_token: user_auth_token,
    }
    return fetch(`${maayotApiUrl}hack-chinese`, {
      method: 'POST',
      headers,
      body: JSON.stringify(content),
    })
      .then((response) => {
        resolve(response)
      })
      .catch((error) => {
        reject(error)
      })
  })
}

export const getIntegrationsTokenForUser = async (memberId: string) => {
  const maayotApiUrl = NODE_ENV === 'production' ? REACT_APP_MAAYOT_API_PROD : REACT_APP_MAAYOT_API_DEV
  const headers = {
    'Content-Type': 'application/json',
    Authorization: `Bearer ${MemberStack.getToken()}`,
  }
  return new Promise((resolve, reject) => {
    axios
      .get(`${maayotApiUrl}integrations/${memberId}`, {
        headers: headers,
      })
      .then((response) => {
        resolve({
          hack_chinese_token: response.data?.hack_chinese_token,
          skritter_token: response.data?.skritter?.skritter_token || '',
          skritter: response.data?.skritter,
        })
      })
      .catch((error) => {
        console.log('error', error)
        reject(error)
      })
  })
}

export const handleAddWordToSkritter = async (payload) => {
  const wordPayload = getSkritterWordPayload(payload)

  const skritter = store.getState().user.skritter
  const memberId = store.getState().user.membership.id

  const expires_in = skritter.expires_in ? parseInt(skritter.expires_in) : 0
  const cTimeSeconds = Math.floor(Date.now() / 1000)
  // expires_in was in seconds
  // if current time less than or equal to Skritter access_token's expiration  time (minus 2 Hour)
  // refetch new access_token
  if (cTimeSeconds >= expires_in - 7200) {
    try {
      const res: any = await refreshSkritterAuthToken(skritter.refresh_token)
      if (res.access_token) {
        payload.skritter_token = res.access_token
        // skritter_token = res.access_token
        payload.refresh_token = res.refresh_token
        payload.expires_in = (parseInt(res.expires_in) + cTimeSeconds).toString()

        const updatePayload = {
          skritter_token: res.access_token,
          refresh_token: res.refresh_token,
          expires_in: payload.expires_in.toString(),
          user_id: skritter.user_id,
          token_type: skritter.token_type,
        }
        const updateRes = await updateNewTokenToDB(memberId, updatePayload)

        store.dispatch({
          type: ACCOUNT_SET_SKRITTER,
          payload: { token: res?.access_token || '', skritter: { ...updatePayload, memberId } },
        })
        localStorage.setItem('skritterData', JSON.stringify({ ...skritter, ...res, skritter_token: res.access_token }))
      }
    } catch (error) {}
  }

  const headers = {
    authorization: 'bearer ' + payload.skritter_token,
  }
  return new Promise((resolve, reject) => {
    axios
      .post(`https://api.skritter.com/v3/vocabs/add-to-list`, wordPayload, {
        headers: headers,
      })
      .then((response) => {
        resolve(response.data)
      })
      .catch((error) => {
        // console.log('error', error)
        reject(error)
      })
  })
}

// logout
export const logoutUser = () => {
  store.dispatch({
    type: SET_UNAUTHENTICATED,
  })
  clearLocal()
  MemberStack.logout()
  posthog.reset(true)
}

export const clearLocal = () => {
  localStorage.removeItem('user')
  localStorage.removeItem('skritterData')
  localStorage.removeItem('persist:storymaayot')
  localStorage.removeItem('persist:quizmaayot')
  localStorage.removeItem('persist:questionmaayot')
  localStorage.removeItem('persist:streakmaayot')
  localStorage.removeItem('persist:levelmaayot')
  localStorage.removeItem('persist:onboardingmaayot')
}
