import { SET_CURRENT_CORRECTION, SET_CORRECTIONS, SET_CHINESE_WORD_FONT_SIZE, SET_IS_MOB_NAV } from '../types'

interface IDashboardState {
  corrections: any
  currentStoryCorrection: any
  chineseWordFontSize: any
  isMobNav: boolean
}

const initialState: IDashboardState = {
  corrections: [],
  currentStoryCorrection: null,
  chineseWordFontSize: {
    label: '24px',
    sessionTitle: '28px',
    storyTitle: '24px',
    storyContent: '24px',
    pinyinValue: '12px',
    newWordsTitle: '40px',
    newWordsExample: '18px',
  },
  isMobNav: true,
}

export default function storyReducer(state = initialState, action: any) {
  switch (action.type) {
    case SET_CORRECTIONS:
      return {
        ...state,
        corrections: [...action.payload],
        currentStory: {},
      }
    case SET_CURRENT_CORRECTION:
      return {
        ...state,
        currentStoryCorrection: action.payload,
      }
    case SET_CHINESE_WORD_FONT_SIZE:
      return {
        ...state,
        chineseWordFontSize: action.payload,
      }
    case SET_IS_MOB_NAV:
      return {
        ...state,
        isMobNav: action.payload,
      }
    default:
      return state
  }
}
