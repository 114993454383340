import React, { useEffect, useMemo, useState, useRef } from 'react'
import { connect, useSelector } from 'react-redux'
import {
  getStory,
  setStep,
  saveLastStep,
  translate,
  wordDoesNotKnow,
  wordDoKnow,
} from '../../redux/actions/storyAction'
import { getQuiz, submitQuiz, getGeneralResult } from '../../redux/actions/quizAction'
import { storyStep, MemberStepInStory, characterConst, converterType, wordType } from '../../shared/constant'
import QuizAnswer from './QuizAnswer'
import QuizResult from './QuizResult'
import Story from './Story'
import history from '../../browserHistory'
import Loader from '../../containers/loader/loader'
import NavigationFooter from '../../containers/navigation-footer/NavigationFooter'
import { QUESTION, STORY } from 'paths'
import QuizOptions from './QuizOptions'
import QuizQuestion from './QuizQuestion'
import Translate from '../../containers/popup/Translate'
import { AddWordToHackChinese, handleAddWordToSkritter } from 'redux/actions/userActions'
import { getQuestion } from 'redux/actions/questionAction'
import WithRedirect from 'containers/WithRedirect'
import { REGEX } from 'utils/regex'

const activeTabConst = {
  quiz: 1,
  story: 2,
}
const activeStateConst = {
  loading: '-1',
  quiz: '1',
  answer: '2',
  result: '3',
  story: '4',
}
const selectedIcon = <i className='fa fa-check-circle color-primary' />
const unSelectedIcon = <i className='fa fa-circle-o' />

const eyeIcon = <i className='fa fa-eye' />
const eyeSlashIcon = <i className='fa fa-eye-slash' />

const Quiz: React.FC<any> = ({
  storyId,
  quizDetail = {},
  getStory,
  getQuiz,
  submitQuiz,
  getGeneralResult,
  story,
  storyLevel,
  quizAnswer,
  quizResult,
  isExistsAnswer,
  lastStepEnum,
  saveLastStep,
  credentials,
  questionDetail,
  user_auth_token,
  skritter_token,
  errors,
  getQuestion,
  question,
  needGetData,
}) => {
  const { memberId } = credentials
  const optionsContent = quizDetail?.optionsWithContent?.map((optionsContent) => optionsContent?.opContect)?.flat()
  const chineseWordFontSize = useSelector((state) => state?.['dashboard']?.chineseWordFontSize)
  const popupRef = useRef<HTMLInputElement>(null)
  const contentStoryRef = useRef(document.createElement('div'))
  const [selected, setSelected] = useState({ value: false, label: '' })
  const [activeTab, setActiveTab] = useState(activeTabConst.quiz)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [gettingResult, setGettingResult] = useState(false)
  const [showPinYin, setShowPinYin] = useState(false)
  const [transVisible, setTransVisible] = useState(false)
  const [transData, setTransData] = useState({})
  const [activeIndex, setActiveIndex] = useState('-1')
  const [quizWord, setQuizWord] = useState<any>(quizDetail ? [...quizDetail?.quizContent, ...optionsContent] : [])

  useEffect(() => {
    document.addEventListener('scroll', onTranslateClose)
    document.addEventListener('click', onDocumentClick)
    return () => {
      document.removeEventListener('scroll', onTranslateClose)
      document.removeEventListener('click', onDocumentClick)
    }
  }, [contentStoryRef, questionDetail])

  const setTranslate = (word: string, posTop: number, posLeft: number, data: any) => {
    setTransVisible(true)
    const type = story?.oldWords?.includes(word)
      ? wordType.OldWord
      : story?.newWord1 === word || story?.newWord2 === word
      ? wordType.NewWord
      : wordType.Word
    setTransData({
      type,
      top: posTop,
      left: posLeft,
      word,
      definition: data.pinyin,
      example1: data?.english,
      example2: '',
      level: 'HSK 5',
    })
  }

  const handleTranslate = (event) => {
    if (
      (event.type === 'mouseover' || event.type === 'click') &&
      event.target.innerText &&
      event.target.innerText.length > 0
    ) {
      trans(event.target.innerText, event.clientY, event.clientX)
    }
  }

  const trans = (word: string, posTop: number, posLeft: number) => {
    if (/^[&.-”“，、；？@!#$%^&*()]+$/.test(word) || word.trim() === '。') {
      setTransVisible(false)
      return
    }

    const wordTranslate = quizDetail?.allquizopContent[0]?.filter(
      (iword) =>
        (globalThis.characterPreference === characterConst.traditional
          ? converterType.converterTraditional(iword.word)
          : converterType.converterSimplified(iword.word)) === word,
    )
    // if (wordTranslate && wordTranslate.length > 0) {
    //   setTranslate(word, posTop, posLeft, wordTranslate[0])
    // }
    if (wordTranslate && wordTranslate.length > 0) {
      setTranslate(word, posTop, posLeft, wordTranslate[0])
      if (user_auth_token) {
        AddWordToHackChinese(wordTranslate[0], user_auth_token)
      }
      if (skritter_token) {
        handleAddWordToSkritter({
          word,
          translate: wordTranslate[0],
          title: story?.storyTitleContent,
          skritter_token: skritter_token,
        })
      }
    } else {
      translate(word)
        .then((res) => {
          return res.json()
        })
        .then((data) => {
          if (data && data.length > 0) {
            setTranslate(word, posTop, posLeft, data[0])
          }
        })
        .catch((err) => {
          console.log(err)
        })
    }
  }

  const onTranslateClose = () => {
    setTransVisible(false)
  }
  const onDocumentClick = (e) => {
    if (!popupRef?.current?.contains(e.target)) {
      setTransVisible(false)
    }
  }

  const handleClickWord = (event, word, key) => {
    handleTranslate(event)
    if (typeof word !== 'string' && !REGEX.specialChars.test(word?.word)) {
      const quizWords = quizWord
        .filter?.((wordInfo) => {
          if (wordInfo?.word.includes('<p>') || wordInfo?.word.includes('</p>') || wordInfo?.word.includes('</p><p>')) {
          } else {
            return wordInfo
          }
        })
        ?.filter((wordInfo: any) => wordInfo?.word !== word?.word)
      setQuizWord(quizWords)
      wordDoesNotKnow({ word: word?.word, memberId, storyId })
    }
    setActiveIndex(activeIndex === key ? `-1` : key)
    event.preventDefault()
    event.stopPropagation()
  }

  useEffect(() => {
    if (storyId && storyLevel && memberId) {
      if (lastStepEnum !== null && lastStepEnum < storyStep.Step2) {
        saveLastStep(MemberStepInStory.QUIZ, storyLevel, memberId, storyId)
      }
      if (!quizDetail?.quiz) {
        getQuiz(storyId, storyLevel, memberId).then((result) => {
          const optionsContent = result?.optionsWithContent?.map((option) => option?.opContect).flat()
          setQuizWord([...result?.quizContent, ...optionsContent])
          if (result.memberAnswer) {
            getResult()
          }
        })
      }
    }
  }, [storyId, storyLevel, memberId])

  // useEffect(() => {
  //   if (!question?.question && storyId && storyLevel && memberId) {
  //     getQuestion(storyId, storyLevel, memberId)
  //   }
  // }, [storyId, storyLevel, memberId])
  useEffect(() => {
    window.scrollTo(0, 0)
    setStep(storyStep.Step2)
  }, [])
  useEffect(() => {
    if (JSON.stringify(story) === '{}' && storyLevel && memberId) {
      getStory(storyId, storyLevel, memberId)
    }
  }, [storyLevel, memberId])

  const setTab = (tab = activeTabConst.quiz) => {
    setActiveTab(tab)
  }

  const onSelectQuiz = (option) => {
    setSelected(option)
  }

  const handleChangeChk = () => {
    setShowPinYin(!showPinYin)
  }

  const onSubmit = () => {
    if (!isSubmitting) {
      setIsSubmitting(true)
      submitQuiz(storyId, storyLevel, memberId, selected.value)
        .then((res) => {
          setIsSubmitting(false)
        })
        .catch((e) => {
          setIsSubmitting(false)
        })
    }
  }
  const getResult = () => {
    setGettingResult(true)
    const handleDone = () => setGettingResult(false)
    getGeneralResult(storyId).then(handleDone).catch(handleDone)
  }

  let activeState = activeStateConst.loading
  if (activeTab === activeTabConst.story) {
    activeState = activeStateConst.story
  } else if (isExistsAnswer) {
    if (quizResult) {
      activeState = activeStateConst.result
    } else {
      activeState = activeStateConst.loading
    }
  } else if (quizDetail) {
    if (quizResult) {
      activeState = activeStateConst.result
    } else if (quizAnswer && quizAnswer.selectedAnswer && quizAnswer.rightAnswer) {
      activeState = activeStateConst.answer
    } else {
      activeState = activeStateConst.quiz
    }
  }
  const icon = useMemo(() => {
    return showPinYin ? eyeSlashIcon : eyeIcon
  }, [showPinYin])

  const audio = useMemo(() => story.audio, [story])

  const onNext = () => {
    const uniqueItems = {}
    quizWord.forEach((item) => {
      uniqueItems[item.word] = item
    })
    const wordList = Object.values(uniqueItems)
      ?.filter((wordInfo: any) => !REGEX.specialChars.test(wordInfo?.word))
      .map((wordInfo: any) => wordInfo?.word)
    wordDoKnow({ wordList, storyId, memberId })
    history.push(`${QUESTION}/${storyId}`)
  }

  return (
    <div>
      <>
        <div ms-membership='free' className='section-2'>
          <div className='section-story w-container'>
            <div className='w-row'>
              <div className='w-col w-col-6 content-left'>
                <span className='introduction-title'>Quiz</span>
              </div>
              <div className='w-col-6  w-col-medium-6 w-col-small-6 w-col-tiny-6  pinyin-button pr-10'>
                {/* This is the button you can uncommment to show Pinyin */}
                {/*  <button
                  className='button-2 bg-light-gray w-button'
                  onClick={() => {
                    handleChangeChk()
                  }}
                >
                  {icon}
                  {showPinYin ? ` Hide PinYin` : ` Show PinYin`}
                </button> */}
              </div>
            </div>
            {(errors === 'Error' || errors === null) && story && (
              <>
                <div className='w-nav story-nav-content quiz-tabs-main-div'>
                  <a
                    className={(activeTab === activeTabConst.quiz && 'link-block active') || 'link-block'}
                    onClick={() => setTab(activeTabConst.quiz)}
                  >
                    Quiz
                  </a>
                  <a
                    className={(activeTab === activeTabConst.story && 'link-block active') || 'link-block'}
                    onClick={() => setTab(activeTabConst.story)}
                  >
                    Story
                  </a>
                </div>
                {activeState === activeStateConst.loading && <Loader />}
                {activeState === activeStateConst.quiz && (
                  <div className='w-row section-story-content'>
                    <div className='w-col w-col-9 content-left'>
                      <div className='session-title'>
                        {globalThis.characterPreference === characterConst.traditional ? (
                          <QuizQuestion
                            questionDetail={quizDetail.quizContent}
                            showPinYin={showPinYin}
                            story={story}
                            handleClickWord={handleClickWord}
                            activeIndex={activeIndex}
                            chineseWordFontSize={chineseWordFontSize}
                          />
                        ) : (
                          <QuizQuestion
                            questionDetail={quizDetail.quizContent}
                            showPinYin={showPinYin}
                            story={story}
                            activeIndex={activeIndex}
                            handleClickWord={handleClickWord}
                            chineseWordFontSize={chineseWordFontSize}
                          />
                        )}
                      </div>

                      <div className='quiz-answers'>
                        <div className='form-quiz-answers'>
                          {(quizDetail.options || []).map((option, ind) => {
                            return (
                              <div className='form-item m-pointer m-flx' key={ind}>
                                <div className='m-radio' onClick={() => onSelectQuiz(option)}>
                                  {selected.value == option.value ? selectedIcon : unSelectedIcon}
                                </div>
                                <span
                                  style={{
                                    fontSize: chineseWordFontSize?.newWordsExample,
                                    lineHeight: '1.5',
                                    letterSpacing: '1.5px',
                                  }}
                                  onClick={() => onSelectQuiz(option)}
                                >
                                  <QuizOptions
                                    iterator={ind}
                                    quizDetail={quizDetail.optionsWithContent[ind]}
                                    showPinYin={showPinYin}
                                    story={story}
                                    activeIndex={activeIndex}
                                    handleClickWord={handleClickWord}
                                  />
                                </span>
                              </div>
                            )
                          })}
                        </div>
                      </div>
                      {selected.value && (
                        <div className='quiz-button-answer'>
                          <div className='disable-correction-container new-tooltip' tabIndex={1}>
                            <button className='button-2 w-button green-btn-class' onClick={onSubmit}>
                              {!isSubmitting ? 'Submit Answer' : <Loader showText={false} />}
                            </button>
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                )}
                {activeState === activeStateConst.answer && (
                  <QuizAnswer loading={gettingResult} result={quizAnswer} onClick={getResult} />
                )}
                {activeState === activeStateConst.result && (
                  <QuizResult
                    user_auth_token={user_auth_token}
                    skritter_token={skritter_token}
                    quizResult={quizResult}
                    quizDetail={quizDetail}
                    selected={quizAnswer}
                    story={story}
                    chineseWordFontSize={chineseWordFontSize}
                  />
                )}
                {activeState === activeStateConst.story && (
                  <div className='w-row section-story-content section-story'>
                    <div className='w-col w-col-7 w-col-small-12 content-left '>
                      <div
                        style={{
                          fontSize: chineseWordFontSize?.storyContent,
                          lineHeight: '1.5',
                          letterSpacing: '1.5px',
                        }}
                        className='quiz-story story-content'
                      >
                        <Story story={story} user_auth_token={user_auth_token} skritter_token={skritter_token} />
                      </div>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <NavigationFooter
          className={'right-active'}
          audio={audio}
          backPath={`${STORY}/${storyId}`}
          backText={'Back'}
          nextText={'Next Step'}
          nextPath={`${QUESTION}/${storyId}`}
          // nextOnclick={onNextClick}
          nextOnclick={onNext}
        />
      </>
      <Translate ref={popupRef} visible={transVisible} data={transData} onClose={onTranslateClose} />
    </div>
  )
}

const mapStateToProps = (state: any, ownProps: any) => ({
  user_auth_token: state.user.user_auth_token,
  skritter_token: state.user.skritter_token,
  credentials: state.user.credentials,
  quizDetail: state.quiz.quizDetail,
  isExistsAnswer: state.quiz.isExistsAnswer,
  quizAnswer: state.quiz.quizAnswer,
  quizResult: state.quiz.quizResult,
  story: state.story.currentStory,
  storyLevel: state.story.storyLevel,
  errors: state.UI.errors,
  storyId: ownProps.match.params.id,
  lastStepEnum: state.story.lastStepEnum,
  needGetData:
    (state.user.credentials.level.length > 0 || state.user.credentials.customFields?.level.length > 0) &&
    !state.story.errors &&
    (JSON.stringify(state.story.currentStory) === '{}' || state.story.currentStory.id !== ownProps.match.params.id),
  //
  question: state.question,
})
const mapActionsToProps = {
  getQuiz,
  submitQuiz,
  getGeneralResult,
  getStory,
  saveLastStep,
  //
  getQuestion,
}
export default connect(mapStateToProps, mapActionsToProps)(WithRedirect(Quiz))
